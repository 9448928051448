import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLinkedin } from '@fortawesome/free-brands-svg-icons';
import '../css/Footer.css';

const Footer = () => (
    <footer className="footer">
        <div className="footer-content">
            <a
                href="https://www.linkedin.com/in/yogadhveep-arora"
                target="_blank"
                rel="noopener noreferrer"
                className="linkedin-link"
            >
                <FontAwesomeIcon icon={faLinkedin} size="2x" />
            </a>
            <p>&copy; 2024 Yogadhveep Arora</p>
        </div>
    </footer>
);

export default Footer;
