import React, { useState } from 'react';
import { Link } from 'react-scroll';
import '../css/Navbar.css';

const Navbar = () => {
    const [sidebar, setSidebar] = useState(false);

    const toggleSidebar = () => {
        setSidebar(!sidebar);
    };

    return (
        <>
            <nav className="navbar">
                <h1 className="navbar-logo">Yogadhveep Arora</h1>
                <div className="menu-icon" onClick={toggleSidebar}>
                    &#9776;
                </div>
                <div className={`navbar-links ${sidebar ? 'active' : ''}`}>
                    <Link to="about" smooth={true} offset={0} className="navbar-link">About</Link>
                    <Link to="education" smooth={true} offset={0} className="navbar-link">Education</Link>
                    <Link to="experience" smooth={true} offset={0} className="navbar-link">Experience</Link>
                    <Link to="projects" smooth={true} offset={0} className="navbar-link">Projects</Link>
                </div>
            </nav>
            <div className={`sidebar ${sidebar ? 'active' : ''}`}>
                <div className="close-icon" onClick={toggleSidebar}>
                    &times;
                </div>
                <Link to="about" smooth={true} offset={0} className="sidebar-link" onClick={toggleSidebar}>About</Link>
                <Link to="education" smooth={true} offset={0} className="sidebar-link" onClick={toggleSidebar}>Education</Link>
                <Link to="experience" smooth={true} offset={0} className="sidebar-link" onClick={toggleSidebar}>Experience</Link>
                <Link to="projects" smooth={true} offset={0} className="sidebar-link" onClick={toggleSidebar}>Projects</Link>
            </div>
        </>
    );
};

export default Navbar;