import React from 'react';
import '../css/HeroSection.css';
import { scroller } from 'react-scroll';

const HeroSection = () => {
    const scrollToAbout = () => {
        scroller.scrollTo('about', {
            smooth: true,
            offset: 0,
        });
    };

    return (
        <div className="hero">
            <h1 className="hero-title">Yogadhveep Arora</h1>
            <p className="hero-subtitle">Innovating Tomorrow's Technology</p>
            <a href="#about" className="hero-button" onClick={scrollToAbout}>Learn More</a>
            <a href="mailto:yogadhveep.arora@gmail.com" className="hero-button">Contact Me</a>
            <div className="scroll-down-arrow" onClick={scrollToAbout}>
                &#x25BC;
            </div>
        </div>
    );
};

export default HeroSection;