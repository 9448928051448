import React from 'react';
import '../css/Education.css';

const Education = () => (
    <section id="education" className="education-section">
        <h2 className="education-title">Education</h2>
        <div className="education-content">
            <h3>Computer Science & Electronics (MEng)</h3>
            <p>University of Bristol | September 2022 - Present</p>
        </div>
        <div className="education-content">
            <h3>Sixth Form</h3>
            <p>Tiffin School, Kingston | September 2020 - August 2022</p>
        </div>
    </section>
);

export default Education;
