import React from 'react';
import { Helmet } from 'react-helmet';
import Navbar from './components/Navbar';
import HeroSection from './components/HeroSection';
import About from './components/About';
import Education from './components/Education';
import Experience from './components/Experience';
import Projects from './components/Projects';
//import Contact from './components/Contact';
import Footer from './components/Footer';

const App = () => (
    <>
        <Helmet>
            <title>Yogadhveep Arora</title>
            <meta name="description" content="Yogadhveep Arora's Portfolio"/>
            <meta name="keywords" content="Yogadhveep Arora, Portfolio"/>
            <link rel="icon" href="%PUBLIC_URL%/logo.svg" />
        </Helmet>
        <Navbar/>
        <HeroSection/>
        <About/>
        <Education/>
        <Experience/>
        <Projects/>
        <Footer/>
    </>
);

export default App;
