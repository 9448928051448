import React from 'react';
import '../css/About.css';

const About = () => (
    <section id="about" className="about-section">
        <h2 className="about-title">About Me</h2>
        <p className="about-text">
            Aspiring to revolutionise the field of technology. Dedicated to contributing to the modern world by
            innovating new solutions and advancing current systems. Through constant learning and problem solving,
            honing the knowledge and skills to succeed in the technology industry. Aiming to be at the forefront of
            shaping a more innovative and technological advanced future.
        </p>
    </section>
);

export default About;
