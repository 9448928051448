import React from 'react';
import '../css/Experience.css';

const Experience = () => (
    <section id="experience" className="experience-section">
        <h2 className="experience-title">Work Experience</h2>
        <div className={"experience-content"}>
            <h3>Engineering Faculty Representative</h3>
            <h3>University of Bristol</h3>
            <p>July 2024 - Present</p>
            <ul>
                <li>Resolved faculty concerns through chairing Faculty Student Staff Liaison Committees</li>
                <li>Addressed the impact of university policies by conversing with the Vice-Chancellor at Senate</li>
                <li>Fostered the support of over 100 representatives to pass new motions at Student Council</li>
                <li>Delivered upon the feedback from over 3000 students, ensuring transparency through regular updates
                </li>
            </ul>
        </div>
        <div className="experience-content">
            <h3>Software Engineer Intern</h3>
            <h3>MeshIQ</h3>
            <p>June 2024 - August 2024</p>
            <ul>
                <li>Created Ansible playbooks to automate the deployment of Kubernetes clusters</li>
                <li>Automated the installation of MeshIQ services, saving 2 days of time</li>
                <li>Developed the frontend GUI using the React architecture to call the API to run the playbooks</li>
                <li>Built a backend API with endpoints to handle the actions called by the frontend</li>
                <li>Demonstrated the product and automation to the Vice-President of MeshIQ</li>
            </ul>
        </div>
        <div className="experience-content">
            <h3>Vice-President</h3>
            <h3>Bristol Electrical and Electronic Engineering Society (BEEES)</h3>
            <p>May 2023 - August 2024</p>
            <ul>
                <li>Managed and led the BEEES committee to achieve numerous society awards</li>
                <li>Formed a strategic plan, which has resulted in the most profitable and successful year</li>
                <li>Executed the most significant tech events such as hackathons, robot wars, and workshops</li>
                <li>Arranged the university’s biggest tech talk till date through tactical marketing</li>
                <li>Secured funding of £10,000 for the society by gaining the interest of external partners</li>
                <li>Improved the society's operations, which has enhanced the experience at events</li>
            </ul>
        </div>
        <div className={"experience-content"}>
            <h3>Treasurer</h3>
            <h3>Computer Science Society (CSS)</h3>
            <p>May 2023 - August 2024</p>
            <ul>
                <li>Managed the society’s budget, amounting to successful events with minimal resources spent</li>
                <li>Established 3 new streams of income to support the society’s activities</li>
                <li>Coordinated with over 10 external organisations for joint events, collaborations, and hackathons
                </li>
                <li>Ensured financial growth through negotiating long-term contracts with external partners</li>
            </ul>
        </div>
        <div className={"experience-content"}>
            <h3>Course & Department Representative</h3>
            <h3>Computer Science & Electronics, University of Bristol</h3>
            <p>September 2022 - Present</p>
            <ul>
                <li>Communicated and mediated with staff members to resolve urgent issues impacting over 100 students</li>
                <li>Presented with 2 leadership awards for achieving outstanding outcomes, earning widespread appreciation from staff and students</li>
                <li>Enhanced the student learning experience through enacting on student feedback
                </li>
                <li>Established changes in course structure that have led to better learning outcomes</li>
            </ul>
        </div>
    </section>
);

export default Experience;
