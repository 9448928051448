import React from 'react';
import '../css/Projects.css';

const Projects = () => (
    <section id="projects" className="projects-section">
        <h2 className="projects-title">Projects</h2>
        <div className="project-content">
            <h3>Game of Life on the Cloud</h3>
            <ul>
                <li>Developed a concurrent version of Conway's Game of Life in Go, leveraging goroutines and channels
                    for efficient parallel processing and state management
                </li>
                <li>Deployed the concurrent Game of Life application on AWS, utilising EC2 instances for distributed
                    computing power and scalability
                </li>
                <li>Conducted performance benchmarking and profiling, leading to targeted optimisations that reduced
                    execution time and resource utilisation
                </li>
                <li>Tested using a suite of unit and integration tests in Go</li>
            </ul>
        </div>
        <div className="project-content">
            <h3>StockWatch</h3>
            <ul>
                <li>Published a fully functional website that allowed users to compare the stock levels of different
                    supermarkets in the vicinity
                </li>
                <li>Collaborated with a team of 6 developers using Git for version control
                </li>
                <li>Converted the stock data into graphs using pandas for data analysis
                </li>
                <li>Built a User Interface (UI) to allow users to interact with the data and stock levels</li>
                <li>Tested and optimised the responsiveness of the website, reducing latency by 50ms</li>
            </ul>
        </div>
    </section>
);

export default Projects;
